<template>
    <div id="chat">
        <div class="chatBox">
            <div class="chatBox-top">
                <div class="title"><span>录音-请录制1s左右的音频样本</span></div>
            </div>
            <div ref="main" class="chatBox-middle">
                <span class="time-text">{{ this.recordTime}} </span>
                <span class="time-sub-text">s</span>
            </div>
            <div class="chatBox-bottom">
                <van-button round type="primary" size="large" @touchstart="startRecord" @touchend="endRecord">录制</van-button>
                <van-button round type="success" style="margin-left: 1rem;" size="large" @touchstart="startRecord" @touchend="endRecord">播放</van-button>
            </div>
        </div>
	</div>
</template>
<script>
import { mapState, mapAcAtions, mapMutations } from 'vuex'
import { Field,Button,Toast,Divider,Image } from 'vant'
import  moment, { duration }  from "moment"
import config from '../config'
import MarkdownIt from 'markdown-it';
import GPTService from '../services/GPTService'
const md = new MarkdownIt();
export default {
    name: 'chat',
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      [Divider .name]: Divider,
      [Image .name]: Image,
      [Toast .name]: Toast,
    },
    computed: {
        ...mapState({
            isLogin: state => state.user.isLogin,
            userName: state => state.user.userName,
            token: state => state.user.token,
            photo: state => state.user.photo,
            nickName: state => state.user.nickName,
        }),
    },
    data () {
        return {
            NickName:'',
            Profile:'',
            UserName:'',
            Profile:'',
            Photo:'',
            session_id:'',
            recordTime:0.0,
            audioData:[],
        } 
    },
    methods: {
        record(){
            fetch(config.apiHost+'/api/gpt/generate/', {
                method: 'POST',
                headers: {
                    'Access-Token':''
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(_data),
            })
            .then(response => response.json())
            .then(json=>{
                if(json.Success){
                    let gptMessage = {
                        id:4,
                        name:'GPT-4',
                        isGPT:true,
                        isCode:false,
                        time:new Date(),
                        images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
                        message:'',
                    }
                    // this.currentChatMessage=gptMessage;
                    // this.appendMessage(gptMessage);
                    // this.messages = ''
                }
            })
            .catch(e => {
                console.log(e);
            });
        },
        recordAudio(){
           // 获取音频流
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    // 创建MediaRecorder对象
                    this.recorder = new MediaRecorder(stream);
                    // 创建一个用于保存录音数据的数组
                    var chunks = [];
                    // 开始录音
                    this.recorder.start();
                    // 录音数据可用时触发该事件
                    this.recorder.ondataavailable = function (event) {
                        chunks.push(event.data);
                    };
                    // 停止录音并播放录制的声音
                    this.recorder.onstop = function (event) {
                        this.audioData=chunks;
                    };
                   
                })
                .catch(function (err) {
                    console.log('获取音频流失败：', err);
                });
        },
        playAudio(){
            // 创建AudioContext对象
            var audioContext = new AudioContext();
            // 创建AudioBuffer对象
            var audioBuffer = audioContext.createBuffer(numberOfChannels, length, sampleRate);
            // 将音频数据存储在AudioBuffer对象中
            for (var channel = 0; channel < numberOfChannels; channel++) {
                var channelData = audioBuffer.getChannelData(channel);
                for (var i = 0; i < length; i++) {
                    // 将音频数据存储在channelData数组中
                    channelData[i] = data[i + channel * length];
                }
            }
            // 创建AudioBufferSourceNode对象
            var sourceNode = audioContext.createBufferSource();
            // 设置音频源为AudioBuffer对象
            sourceNode.buffer = audioBuffer;
            // 连接AudioBufferSourceNode对象到音频目标
            sourceNode.connect(audioContext.destination);
            // 播放音频
            sourceNode.start();
        },
        startRecord(){
            if(!this.timer){
                this.timer=setInterval(()=>{
                    this.recordTime=parseFloat((this.recordTime+0.1).toFixed(2));
                },100);
                this.recordAudio();
            }
        },
        endRecord(){
            if(this.timer){
                clearInterval(this.timer);
                this.timer=null;
                this.recordTime=0;
                this.recorder.stop();
                setTimeout(()=>{
                    // 创建Blob对象
                    var blob = new Blob(this.audioData, { type: 'audio/wav' });
                    // 创建Audio对象
                    var audio = new Audio();
                    // 设置音频源为Blob对象的URL
                    audio.src = URL.createObjectURL(blob);
                    // 播放音频
                    audio.play();
                },1000);
            }
        },
         //设置cookie
        setCookie: function (cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            console.info(cname + "=" + cvalue + "; " + expires);
            document.cookie = cname + "=" + cvalue + "; " + expires;
            console.info(document.cookie);
        },
        //获取cookie
        getCookie: function (cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            console.log("获取cookie,现在循环")
            for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            console.log(c)
            while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) != -1){
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        //清除cookie
        clearCookie: function (cname) {
            this.setCookie(cname, "", -1);
        },
        guid2() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            }
            return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
        }
    },
    created: function () {
        
    },
    mounted: function () {//dom已渲染
        var that=this;
        var sessionId=this.getCookie('session_id');
        if(sessionId){
            this.session_id=sessionId;
        }
        else{
            let _session_id=this.guid2();
            this.setCookie('session_id',_session_id,1);//设置1天
            this.session_id=sessionId;
        }
    },
}
</script>
<style lang="scss" scoped>
     @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");
     html,
		body {
			margin:0; padding:20px; width:100%; height:100%;
            -moz-user-select:none;/*火狐*/
            -webkit-user-select:none;/*webkit浏览器*/
            -ms-user-select:none;/*IE10*/
            -khtml-user-select:none;/*早期浏览器*/
            user-select:none;
		}
		#chat {
            // display: flex;
            // height: 100vh;
            // overflow: hidden;
            position:absolute;
            height:100%;
            width:100%;
            overflow: hidden;
            overflow-y: auto;
        }
        .chatBox {
			width: 100%;
            height: 100%;
			background-color: #fff;
            display: flex;
            flex-direction: column;
            // box-sizing: content-box;
			// border-radius: 0.625rem;
            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.5rem;
                height:3rem;
                background-color:#1989fa;
                .title{
                    color: #fff;
                    text-align: center;
                    width: 100%;
                }
            }
            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                // flex:1;
                height: calc(100% - 12rem);
                .time-text{
                    font-size: 5rem;
                    color: #ff7f97;
                }
                .time-sub-text{
                    font-size:4rem;
                    color: #000;
                    margin-top: 0.8rem;
                    margin-left: 0.5rem;
                }
            }
            .chatBox-bottom {
                // height: 10rem;
                padding-top: 1rem;
                display: flex;
                justify-content: center;
                align-items:center ;
                padding:0 2rem;
            }
		}
</style>
